import React, { useState, useRef, useEffect } from 'react';
import SilentAudioWarningModal from './SilentAudioWarningModal';
import ConfirmEndModal from './ConfirmEndModal'; // Import the new modal
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faSquare, faVideo, faVideoSlash, faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import WebcamCapture from './WebcamCapture';
import TimeWarningModal from './TimeWarningModal';



// Audio Worklet Code
const silenceDetectorWorklet = `
class SilenceDetector extends AudioWorkletProcessor {
  constructor() {
    super();
    this.silenceStart = null;
    this.SILENCE_THRESHOLD = 0.01;
    this.SILENCE_DURATION = 1500;
  }

  process(inputs, outputs, parameters) {
    const input = inputs[0];
    const currentTime = this.currentTime;
    if (input.length > 0) {
      const samples = input[0];
      const sum = samples.reduce((acc, val) => acc + Math.abs(val), 0);
      const average = sum / samples.length;

      if (average < this.SILENCE_THRESHOLD) {
        if (this.silenceStart === null) {
          this.silenceStart = currentTime;
        } else if (currentTime - this.silenceStart > this.SILENCE_DURATION / 1000) {
          this.port.postMessage('silence-detected');
          return false;
        }
      } else {
        this.silenceStart = null;
      }
    }
    return true;
  }
}

registerProcessor('silence-detector', SilenceDetector);
`;


const SpinningArc = ({ isActive }) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    style={{
      position: 'absolute',
      top: '0',
      left: '0',
      animation: isActive ? 'spin 2s linear infinite' : 'none',
      pointerEvents: 'none',
    }}
  >
    <circle
      cx="41"
      cy="41"
      r="38"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeDasharray="60 180"
    />
  </svg>
);

const EnhancedAudioRecorder = ({ personaName, conversation_history, personaType, conversation_id, voice, onHistoryUpdate, user, onEmotionalStateChange, onResponseReceived, onEndConversation, time_limit }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isChatbotSpeaking, setIsChatbotSpeaking] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [personaText, setPersonaText] = useState('');
  const [remainingText, setRemainingText] = useState('');
  const [isWorkletLoaded, setIsWorkletLoaded] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [showCallout, setShowCallout] = useState(true);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showTooltip, setShowTooltip] = useState({ mic: false, video: false, stop: false });
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const workletNodeRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioQueueRef = useRef([]);
  const isPlayingRef = useRef(false);
  const timerRef = useRef(null);
  const currentAudioRef = useRef(null);
  const [calloutOpacity, setCalloutOpacity] = useState(0);
  const [isConversationEnded, setIsConversationEnded] = useState(false);
  const [showSilentAudioWarning, setShowSilentAudioWarning] = useState(false); // State to control the modal
  const [isRecordButtonEnabled, setIsRecordButtonEnabled] = useState(true);
  const [showConfirmEndModal, setShowConfirmEndModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(() => {
    console.log("Setting initial remainingTime with time_limit:", time_limit);
    return time_limit;
  });  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showTimeWarning, setShowTimeWarning] = useState(false);


  useEffect(() => {
    console.log("Received time_limit prop:", time_limit);
    if (typeof time_limit === 'number' && !isNaN(time_limit) && time_limit > 0) {
      console.log("Setting remainingTime to:", time_limit);
      setRemainingTime(time_limit);
    } else {
      console.error("Invalid time_limit received:", time_limit);
      // Set a default value or handle the error
    }
  }, [time_limit]);

  useEffect(() => {
    let timer;
    if (isTimerRunning && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 180) {
            setShowTimeWarning(true);
          }
          if (prevTime <= 1) {
            clearInterval(timer);
            setIsTimerRunning(false);
            handleTimerEnd();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  
    return () => {
      clearInterval(timer);
    };
  }, [isTimerRunning, remainingTime, time_limit]);

  const handleTimerEnd = () => {
    setIsConversationEnded(true);
    onEndConversation();
  };

  useEffect(() => {
    console.log("Initial time_limit:", time_limit);
    console.log("Initial remainingTime:", remainingTime);
  }, []);

  useEffect(() => {
    console.log("time_limit changed, updating remainingTime:", time_limit);
    setRemainingTime(time_limit*60);
  }, [time_limit]);

  const handleCloseSilentWarning = () => {
    setShowSilentAudioWarning(false);
    setIsRecordButtonEnabled(true);
    setIsRecording(false);
    setIsWaitingForResponse(false);
  };

  useEffect(() => {
    const loadWorkletModule = async () => {
      const workletBlob = new Blob([silenceDetectorWorklet], { type: 'application/javascript' });
      const workletBlobUrl = URL.createObjectURL(workletBlob);

      try {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        await audioContextRef.current.audioWorklet.addModule(workletBlobUrl);
        console.log('AudioWorklet loaded successfully');
        setIsWorkletLoaded(true);
      } catch (error) {
        console.error('Error loading AudioWorklet:', error);
      } finally {
        URL.revokeObjectURL(workletBlobUrl);
      }
    };

    loadWorkletModule();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    const calloutTimer = setInterval(() => {
      setShowCallout(prev => !prev);
    }, 3000); // Toggle every 3 seconds

    return () => clearInterval(calloutTimer);
  }, []);



  useEffect(() => {
    if (isTimerRunning) {
      timerRef.current = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isTimerRunning]);



  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsWaitingForResponse(true);
      setIsTimerRunning(false);
    }
  };


  const toggleRecording = async () => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    if (isRecording) {
      stopRecording();
    } else {
      // setRemainingTime(prevTime => {
      //   console.log("Resetting timer to time_limit:", time_limit);
      //   return time_limit * 60;
      // });
            await startRecording();
      setIsTimerRunning(true);
    }
  };



  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });

      if (audioContextRef.current.audioWorklet) {
        // Use AudioWorklet
        // Your existing AudioWorklet code
      } else {
        // Fallback to ScriptProcessorNode for older Safari
        const source = audioContextRef.current.createMediaStreamSource(stream);
        const processor = audioContextRef.current.createScriptProcessor(4096, 1, 1);
        processor.onaudioprocess = (event) => {
          const input = event.inputBuffer.getChannelData(0);
          // Process input data...
        };
        source.connect(processor);
        processor.connect(audioContextRef.current.destination);
      }

      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        sendAudioToServer(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setIsTimerRunning(true);

    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };



  const sendAudioToServer = async (audioBlob) => {
    const arrayBuffer = await audioBlob.arrayBuffer();
    const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

    const isMostlySilent = isAudioMostlySilent(audioBuffer);

    if (isMostlySilent) {
      console.warn('Audio is mostly silent. Showing warning.');
      setShowSilentAudioWarning(true);
      setIsRecordButtonEnabled(false);
      setIsTimerRunning(false);
      setIsRecording(false);
      return;
    }

    // Existing code to send the audio to the server...
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio.webm');
    formData.append('persona', personaName);
    formData.append('conversation_history', conversation_history);
    formData.append('personaType', personaType);
    formData.append('conversation_id', conversation_id);
    formData.append('voice', voice);
    formData.append('user', user);

    try {
      const response = await fetch('https://www.nump0.com/persona/conversation_react', {
        method: 'POST',
        body: formData,
        credentials: 'include',
        mode: 'cors',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let persona_text = '';
      let student_text = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        let boundary = buffer.indexOf('\n');
        while (boundary !== -1) {
          const line = buffer.slice(0, boundary);
          buffer = buffer.slice(boundary + 1);
          boundary = buffer.indexOf('\n');

          if (line.trim()) {
            try {
              const data = JSON.parse(line);
              const result = handleServerResponse(data);
              if (result.content) {
                persona_text += result.content;
              }
              if (data.type === 'transcription') {
                student_text = data.content;
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }
      onResponseReceived(conversation_id, persona_text);

      onHistoryUpdate(conversation_id, student_text, persona_text);
      if (!isPlayingRef.current) {
        playNextAudio();
      }
    } catch (error) {
      console.error('Error sending audio to server:', error);
    } finally {
      setIsWaitingForResponse(false);
    }
  };

  const isAudioMostlySilent = (audioBuffer) => {
    const channelData = audioBuffer.getChannelData(0); // Assuming mono audio
    const threshold = 0.01; // Define a threshold for silence
    const silentSamples = channelData.filter(sample => Math.abs(sample) < threshold).length;
    const silentRatio = silentSamples / channelData.length;

    // Consider the audio silent if more than 90% of samples are below the threshold
    return silentRatio > 0.9;
  };


  const handleServerResponse = (data) => {
    console.log('Server response:', data);

    if (data.type === 'first_chunk' || data.type === 'remaining_text') {
      const cleanedContent = data.content.replace(/~~/g, ' ');
      console.log('Data.emotion:', data.emotion);

      // Directly call the parent's emotional state update function
      onEmotionalStateChange(data.emotion);

      if (data.type === 'remaining_text') {
        setRemainingText(prevText => prevText + cleanedContent);
      }

      return { content: cleanedContent, emotionalState: data.emotion };
    } else if (data.type === 'audio') {
      storeAudio(data.content);
    } else if (data.type === 'transcription') {
      return { transcription: data.content };
    }

    return {};
  };


  const storeAudio = (base64Audio) => {
    const audio = new Audio(`data:audio/mp3;base64,${base64Audio}`);
    audioQueueRef.current.push(audio);
    audio.playbackRate = playbackRate;
  };


  const playNextAudio = () => {
    if (audioQueueRef.current.length > 0) {
      isPlayingRef.current = true;
      setIsChatbotSpeaking(true);
      setIsTimerRunning(true); // Resume the timer when audio starts playing
      const audio = audioQueueRef.current.shift();
      audio.playbackRate = playbackRate;
      currentAudioRef.current = audio;
      audio.play();
      audio.onended = () => {
        setIsChatbotSpeaking(false);
        setIsTimerRunning(true); // Pause the timer when audio finishes
        playNextAudio();
      };
    } else {
      isPlayingRef.current = false;
      setIsChatbotSpeaking(false);
      setIsTimerRunning(true); // Ensure timer is paused when all audio is finished
    }
  };


  useEffect(() => {
    if (remainingText) {
      sendRemainingText();
    }
  }, [remainingText]);

  const sendRemainingText = async () => {
    const formData = new FormData();
    formData.append('remainder_text', remainingText);
    formData.append('persona', personaName);
    formData.append('personaType', personaType);
    formData.append('conversation_id', conversation_id);
    formData.append('conversation_history', conversation_history || '');
    formData.append('voice', voice);

    try {
      const response = await fetch('https://www.nump0.com/persona/remainder_text', {
        method: 'POST',
        body: formData,
        credentials: 'include',
        mode: 'cors',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.type === 'audio') {
        const audio = new Audio(`data:audio/mp3;base64,${data.content}`);
        audio.playbackRate = playbackRate;
        audioQueueRef.current.push(audio);
        if (!isPlayingRef.current) {
          playNextAudio();
        }
        // Update the persona text with the remaining text
        setPersonaText(prevText => prevText + data.text);
        // Clear the remaining text after processing
        setRemainingText('');
      } else {
        console.error('Unexpected response type:', data.type);
      }
    } catch (error) {
      console.error('Error sending remaining text to server:', error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {
    audioQueueRef.current.forEach(audio => {
      audio.playbackRate = playbackRate;
    });
    if (currentAudioRef.current) {
      currentAudioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const toggleVideo = () => {
    setIsVideoActive(prev => !prev);
  };

  const handleEndConversation = () => {
    setIsTimerRunning(false);
    setShowConfirmEndModal(true);
  };

  const confirmEndConversation = () => {
    setIsConversationEnded(true);
    setIsTimerRunning(false);
    onEndConversation();
    setShowConfirmEndModal(false);
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <p className="timer">Time Remaining: {formatTime(remainingTime)}</p>
      <div className="flex space-x-4">
        <div className="relative group">
          <button
            className={`control-button mic-button ${(!isRecordButtonEnabled || isChatbotSpeaking || isWaitingForResponse || isConversationEnded) ? 'pointer-events-none opacity-50' : ''}`}
            onClick={toggleRecording}
            disabled={!isRecordButtonEnabled || isChatbotSpeaking || isWaitingForResponse || isConversationEnded}
            onMouseEnter={() => setShowTooltip(prev => ({ ...prev, mic: true }))}
            onMouseLeave={() => setShowTooltip(prev => ({ ...prev, mic: false }))}
          >
            <FontAwesomeIcon
              icon={isRecording ? faSquare : (isChatbotSpeaking || isWaitingForResponse ? faMicrophoneSlash : faMicrophone)}
              className="icon"
            />
            <span className="label">{isRecording ? "Stop" : "Start Message"}</span>
          </button>
          {showTooltip.mic && (
            <div className="custom-tooltip">
              {isRecording ? "Stop recording" : "Start recording your message"}
            </div>
          )}
        </div>

        <div className="relative group">
          <button
            className={`control-button video-button`}
            onClick={toggleVideo}
            onMouseEnter={() => setShowTooltip(prev => ({ ...prev, video: true }))}
            onMouseLeave={() => setShowTooltip(prev => ({ ...prev, video: false }))}
          >
            <FontAwesomeIcon
              icon={isVideoActive ? faVideo : faVideoSlash}
              className="icon"
            />
            <span className="label">Body Language</span>
          </button>
          {showTooltip.video && (
            <div className="custom-tooltip">
              {isVideoActive ? "Disable body language analysis" : "Enable body language analysis"}
            </div>
          )}
        </div>

        <div className="relative group">
          <button
            className="control-button stop-button"
            onClick={handleEndConversation}
            disabled={isConversationEnded}
            onMouseEnter={() => setShowTooltip(prev => ({ ...prev, stop: true }))}
            onMouseLeave={() => setShowTooltip(prev => ({ ...prev, stop: false }))}
          >
            <FontAwesomeIcon
              icon={faCommentSlash}
              className="icon"
            />
            <span className="label">End Dialogue</span>
          </button>
          {showTooltip.stop && (
            <div className="custom-tooltip">
              End the conversation and request mentor report
            </div>
          )}
        </div>
      </div>



      <div className="mt-4">
        <label className="text-white mr-2">Speed:</label>
        <input
          type="range"
          min="0.5"
          max="2.0"
          step="0.1"
          value={playbackRate}
          onChange={(e) => setPlaybackRate(parseFloat(e.target.value))}
        />
        <span className="text-white ml-2">{playbackRate}x</span>
      </div>

      <WebcamCapture isRecording={isRecording && isVideoActive} conversationId={conversation_id} />
      <SilentAudioWarningModal
        isOpen={showSilentAudioWarning}
        onClose={handleCloseSilentWarning}
      />
      <ConfirmEndModal
        isOpen={showConfirmEndModal}
        onClose={() => setShowConfirmEndModal(false)}
        onConfirm={confirmEndConversation}
      />
      <TimeWarningModal
        isOpen={showTimeWarning}
        onClose={() => setShowTimeWarning(false)}
      />
    </div>
  );
};

export default EnhancedAudioRecorder;