import React, { useState } from 'react';
import './MedilistForm.css'; // Assume we'll create this CSS file for custom styles

const MedilistForm = ({ personaName, conversationId }) => {
    const [patientInfo, setPatientInfo] = useState({
      patientName: '',
      dob: '',
      address: '',
      allergies: ''
    });
  
    const [medications, setMedications] = useState([{ medication: '', dose: '', comments: '' }]);
    const [changedMedications, setChangedMedications] = useState([{ medication: '', comments: '' }]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedback, setFeedback] = useState('');
  

  const handlePatientInfoChange = (e) => {
    setPatientInfo({ ...patientInfo, [e.target.name]: e.target.value });
  };

  const handleMedicationChange = (index, field, value) => {
    const newMedications = [...medications];
    newMedications[index][field] = value;
    setMedications(newMedications);
  };

  const handleChangedMedicationChange = (index, field, value) => {
    const newChangedMedications = [...changedMedications];
    newChangedMedications[index][field] = value;
    setChangedMedications(newChangedMedications);
  };

  const addMedicationRow = () => {
    setMedications([...medications, { medication: '', dose: '', comments: '' }]);
  };

  const addChangedMedicationRow = () => {
    setChangedMedications([...changedMedications, { medication: '', comments: '' }]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedback('');

    const formData = {
      personaName,
      conversationId,
      patientName: patientInfo.patientName,
      dob: patientInfo.dob,
      address: patientInfo.address,
      allergies: patientInfo.allergies,
      medications: medications.map(med => med.medication),
      doses: medications.map(med => med.dose),
      comments: medications.map(med => med.comments),
      changedMedications: changedMedications.map(med => med.medication),
      changedComments: changedMedications.map(med => med.comments)
    };

    try {
      const response = await fetch('https://nump0.com/persona/written_medilist_react', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setFeedback(data.medilist_feedback || 'Form submitted successfully.');
    } catch (error) {
      console.error('Error:', error);
      setFeedback('An error occurred while submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
    


return (
    <div className="medilist-container">
        <form id="medicationForm" onSubmit={handleSubmit} className="medilist-form">
            <div className="form-section">
                <p className="form-note">
                    Note: Students must include all aspects of medication use
                    (name, strength, form, dose, route, frequency). Additional administration advice (swallow whole,
                    with food) is not required unless specified.
                </p>

                {/* Patient Info Fields */}
                {Object.entries(patientInfo).map(([key, value]) => (
                    <div className="form-group" key={key}>
                        <label htmlFor={key}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
                        <input
                            type={key === 'dob' ? 'date' : 'text'}
                            id={key}
                            name={key}
                            value={value}
                            onChange={handlePatientInfoChange}
                            required
                            className="form-input"
                        />
                    </div>
                ))}
            </div>

            <div className="form-section">
                <h3 className="section-title">Current medications:</h3>
                <table className="medication-table">
                    <thead>
                        <tr>
                            <th>Medication Name, Strength and Form</th>
                            <th>Dose, route and frequency</th>
                            <th>Other comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {medications.map((med, index) => (
                            <tr key={index}>
                                {Object.keys(med).map((field) => (
                                    <td key={field}>
                                        <textarea
                                            name={`${field}[]`}
                                            value={med[field]}
                                            onChange={(e) => handleMedicationChange(index, field, e.target.value)}
                                            required={field !== 'comments'}
                                            className="form-textarea"
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" className="btn btn-add" onClick={addMedicationRow}>
                    + Add Medication
                </button>
            </div>

            <div className="form-section">
                <h3 className="section-title">Recently changed/ceased medications:</h3>
                <table className="medication-table">
                    <thead>
                        <tr>
                            <th>Medication Name, Strength and Form</th>
                            <th>Comment(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {changedMedications.map((med, index) => (
                            <tr key={index}>
                                {Object.keys(med).map((field) => (
                                    <td key={field}>
                                        <textarea
                                            name={`changed${field.charAt(0).toUpperCase() + field.slice(1)}[]`}
                                            value={med[field]}
                                            onChange={(e) => handleChangedMedicationChange(index, field, e.target.value)}
                                            required
                                            className="form-textarea"
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" className="btn btn-add" onClick={addChangedMedicationRow}>
                    + Add Changed/Ceased Medication
                </button>
            </div>

            <input
                type="submit"
                className={`btn btn-submit ${isSubmitting ? 'submitting' : ''}`}
                value={isSubmitting ? 'Processing' : 'Submit'}
                disabled={isSubmitting}
            />
        </form>
        {feedback && <div className="feedback" dangerouslySetInnerHTML={{ __html: feedback }}></div>}
    </div>
);
};

export default MedilistForm;